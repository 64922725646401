import "../App.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import sledSvg from "../assets/sled.svg";
import wheelSled from "../assets/wheel-sled.png";
import trackSled from "../assets/track-sled.svg";
import {
  Mission,
  SledGroup,
  Sled,
  Waypoint
} from "../C2Client";

import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import * as turf from "@turf/turf";
import { Feature } from "react-mapbox-gl";
import { Geometry } from "@turf/turf";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWV0emVsaG9mZiIsImEiOiJjbGppcnZ4cmcwMmlqM2ZvdHJjNXo0ODMyIn0.M0OFxknurTaON_mKJdYucg";

const defaultCoordinates = {
  lng: -83.698,
  lat: 42.299995,
  zoom: 18,
};

function MissionPlannerMap({
  missions,
  mission,
  waypoint,
  onClick,
}: {
  missions: Mission[],
  mission: Mission | null;
  waypoint?: Waypoint | null;
  onClick: (e: any) => void;
}) {
  const [waypoints, setWaypoints] = useState<any | null>(undefined);

  const mapContainer = useRef<any | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(defaultCoordinates.lng);
  const [lat, setLat] = useState(defaultCoordinates.lat);
  const [zoom, setZoom] = useState(defaultCoordinates.zoom);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [lng, lat],
      zoom: zoom,
      doubleClickZoom: false
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("move", () => {
      if (!map.current) return;
      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
    });

    function handleClick(e: any) {
      if (!map.current) return;
      const features = map.current.queryRenderedFeatures(e.point);
      console.log(e, features);
      onClick(e);
    };

    // https://docs.mapbox.com/mapbox-gl-js/example/drag-a-point/
    // http://localhost:3000/planner?organizationID=4b08a3bd-9ef8-4d5e-8c88-e386a89e5961&missionID=63556b94-0bba-4c08-a78d-dabb960ab23d
    function handleDragStart(e: any) {
      if (!map.current) return;
      map.current.getCanvasContainer().style.cursor = 'grabbing';
      const features = map.current.queryRenderedFeatures(e.point);
      // if (features[0]?.properties?.waypointID) {
      //   e.preventDefault();
      // }

      // e.waypointID = features[0]?.properties?.waypointID;
      // console.log(e, features);
      // onClick(e);
    };

    function handleDragEnd(e: any) {
      if (!map.current) return;
      const features = map.current.queryRenderedFeatures(e.point);
      e.waypointID = features[0]?.properties?.waypointID;
      console.log(e, features);
      map.current.getCanvasContainer().style.cursor = '';
      // onClick(e);
    };

    map.current.on("click", handleClick);
    map.current.on("dragstart", handleDragStart);
    map.current.on("dragend", handleDragEnd);
    // map.current.on("dblclick", onClick);

    return () => {
      if (!map.current) return; // wait for map to initialize
      map.current.off("click", handleClick);
      map.current.off("dragstart", handleDragStart);
      map.current.off("dragend", handleDragEnd);
      // map.current.off("dblclick", onClick);
    }
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("load", () => {
      if (!map.current) return;
      console.debug("map loaded");

      // Load an image from an external URL.
      if (!map.current.hasImage("WP_Green")) {
        console.debug("map does not have image. loading...");
        map.current.loadImage(
          `${window.location.origin}/WP_Green.png`,
          (error: any, image: any) => {
            if (error) console.error(error);
            if (!map.current) return;
            // Add the image to the map style.
            if (!map.current.hasImage("WP_Green")) {
              console.debug("map still does not have image. adding...");
              map.current.addImage("WP_Green", image);
            }
          }
        );
      }
    });
  }, []);

  // useEffect(() => {
  //   if (!map.current || !missions || !mission) return; // wait for map to initialize

  //   console.log("missionChanged: ", mission?.missionName);

  //   const wps: any[] = [];

  //   if (mission) {
  //     mission.waypoints.map((wp, i) => {
  //       wps.push([wp.longitude, wp.latitude]);
  //     });
  //   }

  //   var points = turf.points(wps);
  //   var bbox = turf.bbox(points);
  //   var center = turf.center(points);
  //   console.log(`waypoints: `, wps, points, bbox, center);

  //   setWaypoints(wps);

  //   // Specify that the panTo animation should last 5000 milliseconds.
  //   map.current.panTo(center.geometry.coordinates as mapboxgl.LngLatLike, {
  //     duration: 1000,
  //   });

  //   missions.forEach((m: Mission, i: number) => {
  //     if (!map.current) return;

  //     const missionSource = map.current.getSource(`route-${m?.missionID}`);
  //     if (!missionSource) {
  //       console.debug(`map does not have source. adding route-${m?.missionID}...`);
  //       map.current.addSource(`route-${m?.missionID}`, {
  //         type: "geojson",
  //         data: {
  //           type: "Feature",
  //           properties: {
  //             missionID: m.missionID
  //           },
  //           geometry: {
  //             type: "LineString",
  //             coordinates: m?.waypoints.reduce((p: any[], c: Waypoint) => { 
  //               p.push([c.longitude, c.latitude]);
  //               return p; 
  //             }, [])
  //           },
  //         },
  //       });
  //     } else {
  //       (map.current.getSource(`route-${m?.missionID}`) as GeoJSONSource).setData({
  //             type: "Feature",
  //             geometry: {
  //               type: "LineString",
  //               coordinates: m?.waypoints.reduce((p: any[], c: Waypoint) => { 
  //                 p.push([c.longitude, c.latitude]);
  //                 return p; 
  //               }, [])
  //             },
  //             properties: {},
  //           });
  //     }
  
  //     const missionLayer = map.current.getLayer(`route-${m?.missionID}`);
  //     if (!missionLayer) {
  //       console.debug(`map does not have layer. adding route-${m?.missionID}...`);
  //       map.current.addLayer({
  //         id: `route-${m?.missionID}`,
  //         type: "line",
  //         source: `route-${m?.missionID}`,
  //         layout: {
  //           "line-join": "round",
  //           "line-cap": "round",
  //           "visibility": m.missionID === mission.missionID ? "visible" : "none"
  //         },
  //         paint: {
  //           "line-color": "#34b44a",
  //           "line-width": 8,
  //         },
  //       });
  //     } else {
  //       map.current.setLayoutProperty(
  //         missionLayer.id,
  //         "visibility",
  //         m.missionID === mission.missionID ? "visible" : "none"
  //       );
  //     }
  
  //     m?.waypoints.map((wp, j) => {
  //       if (!map.current) return;
  
  //       const wpSource = map.current.getSource(`point-${m?.missionID}-${j}`);
  //       // Add a data source containing one point feature.
  //       if (!wpSource) {
  //         console.debug(`map does not have source. adding point-${m?.missionID}-${j}...`);
  //         map.current.addSource(`point-${m?.missionID}-${j}`, {
  //           type: "geojson",
  //           data: {
  //             type: "FeatureCollection",
  //             features: [
  //               {
  //                 type: "Feature",
  //                 geometry: {
  //                   type: "Point",
  //                   coordinates: [wp.longitude, wp.latitude]
  //                 },
  //                 properties: {
  //                   missionID: m.missionID,
  //                   waypointID: wp.waypointID
  //                 },
  //               },
  //             ],
  //           },
  //         });
  //       } else {
  //         (map.current.getSource(`point-${m?.missionID}-${j}`) as GeoJSONSource).setData({
  //           type: "FeatureCollection",
  //           features: [
  //             {
  //               type: "Feature",
  //               geometry: {
  //                 type: "Point",
  //                 coordinates: [wp.longitude, wp.latitude]
  //               },
  //               properties: {},
  //             },
  //           ],
  //         });
  //       }
  
  //       const missionWPs = mission.waypoints.map(p => p.waypointID);
  //       const wpLayer = map.current.getLayer(`points-${m?.missionID}-${j}`);
  //       // Add a layer to use the image to represent the data.
  //       if (!wpLayer) {
  //         console.debug(`map does not have layer. adding points-${m?.missionID}-${j}...`);
  
  //         map.current.addLayer({
  //           id: `points-${m?.missionID}-${j}`,
  //           type: "symbol",
  //           source: `point-${m?.missionID}-${j}`,
  //           layout: {
  //             "icon-image": "WP_Green",
  //             "icon-size": 0.5,
  //             "icon-allow-overlap": true,
  //             "icon-ignore-placement": true,
  //             "text-field": (j + 1).toString(),
  //             "text-allow-overlap": true,
  //             "text-ignore-placement": true,
  //             "symbol-sort-key": j,
  //             "symbol-z-order": "source",
  //             "visibility": missionWPs.includes(wp.waypointID) ? "visible" : "none"
  //           },
  //           paint: {
  //             "text-color": "#333333",
  //           },
  //         });
  //       } else {
  //         map.current.setLayoutProperty(
  //           wpLayer.id,
  //           "visibility",
  //           missionWPs.includes(wp.waypointID) ? "visible" : "none"
  //         );
  //       }
  //     });
  //   });
  // }, [missions, mission]);

  useEffect(() => {
    if (!map.current || !mission) return; // wait for map to initialize

    console.log("missionChanged: ", mission?.missionName);

    const wps: any[] = [];

    if (mission) {
      mission.waypoints.map((wp, i) => {
        wps.push([wp.longitude, wp.latitude]);
      });
    }

    var points = turf.points(wps);
    var bbox = turf.bbox(points);
    var center = turf.center(points);
    console.log(`waypoints: `, wps, points, bbox, center);

    setWaypoints(wps);

    // Specify that the panTo animation should last 5000 milliseconds.
    map.current.panTo(center.geometry.coordinates as mapboxgl.LngLatLike, {
      duration: 1000,
    });

    const missionSource = map.current.getSource(`route`);
      if (!missionSource) {
        console.debug(`map does not have source. adding route...`);
        map.current.addSource(`route`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {
              missionID: mission.missionID
            },
            geometry: {
              type: "LineString",
              coordinates: mission?.waypoints.reduce((p: any[], c: Waypoint) => { 
                p.push([c.longitude, c.latitude]);
                return p; 
              }, [])
            },
          },
        });
      } else {
        (map.current.getSource(`route`) as GeoJSONSource).setData({
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: mission?.waypoints.reduce((p: any[], c: Waypoint) => { 
                  p.push([c.longitude, c.latitude]);
                  return p; 
                }, [])
              },
              properties: {},
            });
      }
  
      const missionLayer = map.current.getLayer(`route`);
      if (!missionLayer) {
        console.debug(`map does not have layer. adding route...`);
        map.current.addLayer({
          id: `route`,
          type: "line",
          source: `route`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#34b44a",
            "line-width": 8,
          },
        });
      }
  
      const features = mission?.waypoints.map((wp) => {
        const f = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [wp.longitude, wp.latitude]
          },
          properties: {
            missionID: mission.missionID,
            waypointID: wp.waypointID,
            waypointNumber: wp.waypointNumber
          },
        };
        return f;
      });

      const wpSource = map.current.getSource(`waypoints`);
        // Add a data source containing one point feature.
        if (!wpSource) {
          console.debug(`map does not have source. adding waypoints...`);
          map.current.addSource(`waypoints`, {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: features as any
            },
          });
        } else {
          (map.current.getSource(`waypoints`) as GeoJSONSource).setData({
            type: "FeatureCollection",
            features: features as any,
          });
        }

        const wpLayer = map.current.getLayer(`waypoints`);
        // Add a layer to use the image to represent the data.
        if (!wpLayer) {
          console.debug(`map does not have layer. adding waypoints...`);
          map.current.addLayer({
            id: `waypoints`,
            type: "symbol",
            source: `waypoints`,
            layout: {
              "icon-image": "WP_Green",
              "icon-size": 0.5,
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              'text-field': ['get', 'waypointNumber'],
              "text-allow-overlap": true,
              "text-ignore-placement": true,
              "symbol-sort-key": ['get', 'waypointNumber'],
              "symbol-z-order": "source",
            },
            paint: {
              "text-color": "#333333",
            },
          });
        }
  }, [mission]);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{
        height: "70vh",
        width: "100%",
      }}
    >
      <div className="map-position">
        Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom.toFixed(2)}
      </div>
    </div>
  );
}

export default MissionPlannerMap;
