import { Outlet, useNavigate } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/Navbar";
import { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  console.log({ domain, clientId, redirectUri });

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || redirectUri);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/control");
    }
  }, [window.location.pathname]);

  if (!(domain && clientId && redirectUri)) {
    return null;
  } else {
    return (
      <div>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          authorizationParams={{
            redirect_uri: redirectUri,
            audience: audience
          }}
          onRedirectCallback={onRedirectCallback}
          cacheLocation="localstorage"
          useRefreshTokens={true}
        >
          {/* <Navbar /> */}
          <Outlet />
        </Auth0Provider>
      </div>
    );
  }
}

export default App;
