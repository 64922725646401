import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import MissionControl from './routes/MissionControl';
import MissionPlanner from './routes/MissionPlanner';
import Callback from './routes/Callback';
import Settings from './routes/Settings';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "control",
        // index: true,
        element: <MissionControl />,
      },
      {
        path: "planner",
        element: <MissionPlanner />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "callback",
        element: <Callback />,
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
