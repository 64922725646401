import "./App.scss";
import { SledGroup } from "./C2Client";

const SledGroupControl = ({
  sledGroups,
  selectedSledGroup,
  onChange,
  onClick,
}: {
  sledGroups: SledGroup[];
  selectedSledGroup: SledGroup;
  onChange: (gid: string) => void;
  onClick: (sid: string, message: string) => Promise<void>;
}) => {
  // console.log('sledGroups', sledGroups);
  return (
    <div>
      <div className="mb-3">
        <select className="form-select" aria-label="Sled Group Select" placeholder="Select a Sled Group" value={selectedSledGroup?.sledGroupID} onChange={(e) => onChange(e.target.value)}>
          {/* <option selected>Select a Sled Group</option> */}
          {sledGroups &&
            sledGroups.map((g: SledGroup) => (
              <option key={g.sledGroupID} value={g.sledGroupID}>{g.sledGroupName}</option>
            ))}
        </select>
      </div>

      <div className="d-flex justify-content-start">
        <button
          className="btn btn-danger w-50"
          onClick={() => onClick("1", "testing")}
        >
          E-Stop
        </button>
        <button className="btn btn-danger w-50 ms-1">Shut Down</button>
      </div>
    </div>
  );
};

export default SledGroupControl;
