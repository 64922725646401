import "../App.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef, useState } from "react";
// import sledSvg from "../assets/sled.svg";
// import wheelSled from "../assets/wheel-sled.png";
// import trackSled from "../assets/track-sled.svg";
import { Mission, Sled, SledGroup, Waypoint } from "../C2Client";

import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import * as turf from "@turf/turf";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWV0emVsaG9mZiIsImEiOiJjbGppcnZ4cmcwMmlqM2ZvdHJjNXo0ODMyIn0.M0OFxknurTaON_mKJdYucg";

const defaultCoordinates = {
  lng: -83.698,
  lat: 42.299995,
  zoom: 17,
};

function MissionControlMap({
  sledGroups,
  sleds,
  missions,
  activeSledGroup,
  activeSled,
  activeMission,
}: {
  sledGroups: SledGroup[];
  sleds: Sled[];
  missions: Mission[];
  activeSledGroup?: SledGroup | null;
  activeSled?: Sled | null;
  activeMission?: Mission | null;
}) {
  const mapContainer = useRef<any | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(defaultCoordinates.lng);
  const [lat, setLat] = useState(defaultCoordinates.lat);
  const [zoom, setZoom] = useState(defaultCoordinates.zoom);
  const [moveHandled, setMoveHandled] = useState<boolean>(false);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  useEffect(() => {
    if (!map.current || moveHandled) return; // wait for map to initialize

    function handleMapMove() {
      if (!map.current) return;

      // console.debug("map moved", {
      //   lng: map.current.getCenter().lng.toFixed(4),
      //   lat: map.current.getCenter().lat.toFixed(4),
      //   zoom: map.current.getZoom().toFixed(2),
      // });

      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
      setMoveHandled(true);
    }

    map.current.on("move", handleMapMove);
    // map.current.on("moveend", handleMapMove);

    return () => {
      if (!map.current) return;
      map.current.off("move", handleMapMove);
      // map.current.off("moveend", handleMapMove);
      setMoveHandled(false);
    };
  });

  useEffect(() => {
    if (
      !map.current ||
      !sleds ||
      !sledGroups ||
      !missions ||
      !activeSledGroup ||
      !activeSled ||
      !activeMission
    )
      return; // wait for map to initialize

    const activeSleds = sleds.filter(
      (s) => s.sledGroupID === activeSledGroup?.sledGroupID
    );
    const activeSledIDs = activeSleds.map((s) => s.sledID);
    const activeMissionNames = activeSleds.map((s) => s.lastMission);

    //TODO:DAM switch this to active mission? need to do something else when the sled is moving around during the mission
    if (activeSled) {
      var points = turf.points([
        [activeSled.lastLongitude, activeSled.lastLatitude],
      ]);
      var center = turf.center(points);
      // map.current.setCenter(center.geometry.coordinates as mapboxgl.LngLatLike);
      // Specify that the panTo animation should last 5000 milliseconds.
      map.current.panTo(center.geometry.coordinates as mapboxgl.LngLatLike, {
        duration: 100,
      });
    }
    console.debug("map loaded", sleds, missions);

    // Load an image from an external URL.
    if (!map.current.hasImage("wheel-sled")) {
      console.debug("map does not have image. loading...");
      map.current.loadImage(
        `${window.location.origin}/wheel-sled.png`,
        (error: any, image: any) => {
          if (error) console.error(error);
          if (!map.current) return;
          // Add the image to the map style.
          if (!map.current.hasImage("wheel-sled")) {
            console.debug("map still does not have image. adding...");
            map.current.addImage("wheel-sled", image);
          }
        }
      );
    }

    missions.map((m, i) => {
      if (!map.current) return null;
      if (!map.current.getSource(`route-${m.missionID}`)) {
        console.debug(
          `map does not have source. adding route-${m.missionID}...`
        );
        map.current.addSource(`route-${m.missionID}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: m.waypoints.reduce((p: any[], c: Waypoint) => {
                p.push([c.longitude, c.latitude]);
                return p;
              }, []),
            },
          },
        });
      }

      const missionRoute = map.current.getLayer(`route-${m.missionID}`);

      if (!missionRoute) {
        console.debug(
          `map does not have layer. adding route-${m.missionID}...`
        );
        map.current.addLayer({
          id: `route-${m.missionID}`,
          type: "line",
          source: `route-${m.missionID}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
            visibility: activeMissionNames.includes(m.missionName)
              ? "visible"
              : "none",
          },
          paint: {
            "line-color": "#34b44a",
            "line-width": 8,
          },
        });
      } else {
        map.current.setLayoutProperty(
          missionRoute.id,
          "visibility",
          activeMissionNames.includes(m.missionName) ? "visible" : "none"
        );
        console.log({
          mission: m.missionName,
          visibility: (map.current.getLayer(`route-${m.missionID}`) as any)
            .visibility,
        });
      }
      return null;
    });

    sleds.map((s, i) => {
      if (!map.current) return null;
      const sledSource = map.current.getSource(`point-${s.sledID}`);
      // Add a data source containing one point feature.
      if (!sledSource) {
        console.debug(`map does not have source. adding point-${s.sledID}...`);
        map.current.addSource(`point-${s.sledID}`, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [s.lastLongitude, s.lastLatitude],
                },
                properties: {},
              },
            ],
          },
        });
      } else {
        (map.current.getSource(`point-${s.sledID}`) as GeoJSONSource).setData({
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [s.lastLongitude, s.lastLatitude],
                },
                properties: {},
              },
            ],
          });
      }

      const sledPoint = map.current.getLayer(`points-${s.sledID}`);
      // Add a layer to use the image to represent the data.
      if (!sledPoint) {
        console.debug(`map does not have layer. adding points-${s.sledID}...`);
        map.current.addLayer({
          id: `points-${s.sledID}`,
          type: "symbol",
          source: `point-${s.sledID}`, // reference the data source
          layout: {
            "icon-image": "wheel-sled", // reference the image
            "icon-size": 0.5,
            "icon-rotate": s.lastHeading,
            visibility: activeSledIDs.includes(s.sledID) ? "visible" : "none",
          },
        });
        console.log({
          sled: s.sledName,
          visibility: (map.current.getLayer(`points-${s.sledID}`) as any)
            .visibility,
        });
      } else {
        map.current.setLayoutProperty(
          sledPoint.id,
          "visibility",
          activeSledIDs.includes(s.sledID) ? "visible" : "none"
        );
        map.current.setLayoutProperty(
          sledPoint.id,
          "icon-rotate",
          s.lastHeading
        );
        console.log({
          sled: s.sledName,
          visibility: (map.current.getLayer(`points-${s.sledID}`) as any)
            .visibility,
        });
      }
      return null;
    });
  }, [
    sledGroups,
    sleds,
    missions,
    activeSledGroup,
    activeSled,
    activeMission,
  ]);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{
        height: "100vh",
        width: "100%",
      }}
    >
      <div className="map-position">
        Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom.toFixed(2)}
      </div>
    </div>
  );
}

export default MissionControlMap;
