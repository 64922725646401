import { withAuthenticationRequired } from "@auth0/auth0-react";
import "../App.scss";

function Settings() {
  return (
    <div className="">
      <div className="row g-0">
        <div className="col">
          <div className="row p-2">
            <div className="col-12"></div>
          </div>
        </div>
        <div className="col-8"></div>
        <div className="col"></div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Settings);
