import "../App.scss";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MissionPlannerMap from "../components/MissionPlannerMap";
import { Mission, Organization, Sled, SledGroup, Waypoint } from "../C2Client";
import { useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Navbar from "../components/Navbar";
import { randomUUID } from "crypto";

const waitConditions = ["None", "Operator", "External Trigger"];
const waitReleases = ["None", "Continue", "Crosswalk Clear"];

function MissionPlanner() {
  const { user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [organizations, setOrganizations] = useState<any | null>(null);
  const [organizationID, setOrganizationID] = useState<string | null>(null);
  const [organization, setOrganization] = useState<any | null>(null);
  const [missions, setMissions] = useState<any | null>(null);
  const [missionID, setMissionID] = useState<string | null>(null);
  const [mission, setMission] = useState<Mission | null>(null);
  const [waypoint, setWaypoint] = useState<Waypoint | null>(null);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-ym0jxv4f0vjhebp8.us.auth0.com";
  
      try {
        const apiAccessToken = await getAccessTokenWithPopup({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user offline_access",
          },
        });

        // console.log({ apiAccessToken });
        setApiToken(apiAccessToken || '');
        
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
            scope: "openid profile email offline_access read:current_user",
          },
        });
  
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const { user_metadata } = await metadataResponse.json();
  
        setUserMetadata(user_metadata);
      } catch (e: any) {
        console.log(e.message);
      }
    };
  
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (!apiToken) return;

    const fetchOrgs = async () => {
      fetch(`${process.env.REACT_APP_API}/organizations`, {
        headers: { Authorization: `Bearer ${apiToken}` }
      })
      .then(res => res.json())
      .then(data => {
        setOrganizations(data)
      });
    };

    const fetchMissions = async (organizationID: string) => {
      const res = await fetch(`${process.env.REACT_APP_API}/missions?OrganizationID=${organizationID}`);
      const data = await res.json();
      setMissions(data);
    };

    fetchOrgs();
    fetchMissions(organizationID ? organizationID : '');
  }, [apiToken]);
  
  useEffect(() => {
    if (organizations == null || missions == null) return;
    // Code here will run after *every* render
    const url = new URL(window.location.href);
    
    let oid = url.searchParams.get("organizationID") || organizations[0]?.organizationID;
    setOrganizationID(oid);
    const orgData = organizations.find((o: Organization) => o.organizationID === oid);
    setOrganization(orgData as Organization);

    let mid = url.searchParams.get("missionID") || missions[0]?.missionID;
    setMissionID(mid);
    const missionData = missions.find((m: Mission) => m.missionID === mid);
    if (missionData) {
      setMission(missionData);
      window.history.replaceState(null, '', `/planner?organizationID=${oid}&missionID=${mid}`);
    } else {
      window.history.replaceState(null, '', `/planner?organizationID=${oid}`);
    }

    // console.log({ mid, missionData });
  }, [window.location.href, organizations, missions]);

  const changeMission = (mission: string) => {
    const m = missions.find((m: Mission) => m.missionID === mission) as Mission;
    console.log("changeMission", mission, m);
    setMission(m);
    window.history.replaceState(null, '', `/planner?organizationID=${organizationID}&missionID=${m.missionID}`);
  };

  const handleClick = (e: any) => {
    console.log("handleClick", e);
    const newWP = {} as Waypoint;
    newWP.boomerang = false;
    newWP.longitude = e.lngLat.lng;
    newWP.latitude = e.lngLat.lat;
    newWP.speedMS = mission?.defaultSpeedMS as number;
    newWP.waitCondition = 'None';
    newWP.resumeCondition = 'None';
    newWP.waypointID = crypto.randomUUID();
    newWP.waypointNumber = (mission?.waypoints.length as number) + 1;

    const newMissions = missions.filter((m: Mission) => m.missionID !== mission?.missionID);
    const m = {...mission} as Mission;
    m.waypoints?.push(newWP);
    newMissions.push(m);
    setMissions(newMissions);
    setMission(m);
  };

  const handleCondition = (condition: string, value: string, i: number) => {
    const newWaypoints = [...mission?.waypoints as Waypoint[]];
    const newMission = {...mission} as Mission;
    if (condition === 'wait') {
      newWaypoints[i].waitCondition = value;
    }
    if (condition === 'resume') {
      newWaypoints[i].resumeCondition = value;
    }
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };
  
  const handleDelete = (i: number) => {
    const newWaypoints = [...mission?.waypoints as Waypoint[]];
    const newMission = {...mission} as Mission;
    newWaypoints.splice(i, 1);

    for (let index = 0; index < newWaypoints.length; index++) {
      newWaypoints[index].waypointNumber = index + 1;
    }

    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    const newMissions = [...missions].filter(m => m.missionID !== newMission.missionID);
    newMissions.push(newMission);
    setMission(newMission);
    setMissions(newMissions);
  };
  
  const handleSpeed = (value: number, i: number) => {
    const newWaypoints = [...mission?.waypoints as Waypoint[]];
    const newMission = {...mission} as Mission;
    newWaypoints[i].speedMS = value;
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleBoomerang = (checked: boolean, i: number) => {
    const newWaypoints = [...mission?.waypoints as Waypoint[]];
    const newMission = {...mission} as Mission;
    newWaypoints[i].boomerang = checked;
    if (newMission?.waypoints) {
      newMission.waypoints = newWaypoints;
    }
    setMission(newMission);
  };

  const handleSave = (mission: Mission) => {
    if (!apiToken) return;

    const saveMission = async (m: Mission) => {
      
      for (let index = 0; index < m.waypoints.length; index++) {
        m.waypoints[index].waypointNumber = index + 1;
      }

      const response = await fetch(`${process.env.REACT_APP_API}/missions/${m.missionID}`, {
        method: 'PATCH',
        headers: { 
          Authorization: `Bearer ${apiToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(m)
      });
      const result = await response.json();
      setMission(result);
    };

    saveMission(mission);
  };

  const handleCancel = (mission: Mission) => {
    const getMission = async (m: Mission) => {
      const response = await fetch(`${process.env.REACT_APP_API}/missions/${m.missionID}`);
      const result = await response.json();
      setMission(result);
    };

    getMission(mission);
  };

  return (
    <>
    <Navbar organization={organization} />
    <div className="">
      <div className="row g-0">
        <div className="col-12">
          <MissionPlannerMap 
            missions={missions}
            mission={mission} 
            waypoint={waypoint}
            onClick={handleClick} 
          />
        </div>
      </div>
      <div className="row g-0">
        <div className="col-12">
          <div className="row g-0">
            <div className="col-12">
              <div className="d-flex p-2 align-items-center justify-content-between">
                <div className="d-flex">
                  <select
                    className="form-select me-2"
                    aria-label="Mission Select"
                    placeholder="Mission"
                    value={mission?.missionID}
                    onChange={(e) => changeMission(e.target.value)}
                  >
                    {missions &&
                      missions.map((m: Mission) => (
                        <option key={m.missionID} value={m.missionID}>
                          {m.missionName}
                        </option>
                      ))}
                  </select>
                  <input className="form-control me-2 w-25" type="number" value={mission?.defaultSpeedMS} onChange={e => console.log(e.target.value)} />
                  <div className="d-flex justify-content-start text-nowrap">
                  <button
                    className="btn btn-success me-2"
                    onClick={() => console.log("1", "testing")}
                  >
                    Add WP
                  </button>
                  </div>
                </div>
                <div className="d-flex justify-content-start">
                  <button
                    className="btn btn-success w-50"
                    onClick={() => handleSave(mission as Mission)}
                  >
                    Save
                  </button>
                  <button 
                    className="btn btn-outline-secondary w-50 ms-2"
                    onClick={() => handleCancel(mission as Mission)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <hr className="my-1" />
            </div>
            <div className="col-12">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Waypoint #</th>
                    <th scope="col">Longitude</th>
                    <th scope="col">Latitude</th>
                    <th scope="col">Speed (m/s)</th>
                    <th scope="col">Wait Condition</th>
                    <th scope="col">Boomerang</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {mission &&
                    mission.waypoints.map((wp: Waypoint, i) => (
                      <tr key={mission.missionID + '-' + wp.waypointID} onClick={() => setWaypoint(wp)} className={wp.waypointID === waypoint?.waypointID ? 'table-active' : ''}>
                        <th scope="row">{i + 1}</th>
                        <td>{wp.longitude}</td>
                        <td>{wp.latitude}</td>
                        <td>
                          <input className="form-control me-2 w-25" type="number" value={wp.speedMS} onChange={e => handleSpeed(parseFloat(e.target.value), i)} />
                        </td>
                        <td>
                          <div className="d-inline-flex">
                            <select
                              className="form-select me-2"
                              aria-label="Wait Condition Select"
                              placeholder="Wait Condition"
                              value={wp?.waitCondition}
                              onChange={(e) => handleCondition('wait', e.target.value, i)}
                            >
                              {waitConditions &&
                                waitConditions.map((c) => (
                                  <option key={c} value={c}>
                                    {c}
                                  </option>
                                ))}
                            </select>
                            <select
                              className="form-select"
                              aria-label="Wait Release Select"
                              placeholder="Wait Release"
                              value={wp?.resumeCondition}
                              onChange={(e) => handleCondition('resume', e.target.value, i)}
                            >
                              {waitReleases &&
                                waitReleases.map((c) => (
                                  <option key={c} value={c}>
                                    {c}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={wp.boomerang}
                              id={`boomerang-${i}`}
                              onChange={(e) => handleBoomerang(e.target.checked, i)}
                            />
                          </div>
                        </td>
                        <td>
                        <button 
                          className="btn btn-danger"
                          onClick={() => handleDelete(i)}
                        >
                          Delete
                        </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default withAuthenticationRequired(MissionPlanner);
