import "../App.scss";
import SledGroupControl from "../SledGroupControl";
import { MouseEventHandler, useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import sledSvg from "../assets/sled.svg";
import wheelSled from "../assets/wheel-sled.png";
import trackSled from "../assets/track-sled.svg";
import { Mission, Organization, Sled, SledGroup, SledStatus } from "../C2Client";
import MissionControlMap from "../components/MissionControlMap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Navbar from "../components/Navbar";

function MissionControl() {
  const { user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  const [apiToken, setApiToken] = useState<string | null>(null);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [organizations, setOrganizations] = useState<Organization[] | null>(null);
  const [organizationID, setOrganizationID] = useState<string | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [sledGroups, setSledGroups] = useState<SledGroup[] | null>(null);
  const [sledGroupID, setSledGroupID] = useState<string | null>(null);
  const [sledGroup, setSledGroup] = useState<SledGroup | null>(null);
  const [sleds, setSleds] = useState<Sled[] | null>(null);
  const [filteredSleds, setFilteredSleds] = useState<Sled[] | null>(null);
  const [sledID, setSledID] = useState<string | null>(null);
  const [sled, setSled] = useState<Sled | null>(null);
  const [missions, setMissions] = useState<Mission[] | null>(null);
  const [sledMission, setSledMission] = useState<Mission | undefined>(undefined);
  const [controls, setControls] = useState<any | null>({
    select: false,
    load: true,
    clear: false,
    start: false,
    pause: false,
    continue: false,
    stop: false,
    shutdown: true,
  });

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN; //"dev-ym0jxv4f0vjhebp8.us.auth0.com";
  
      try {
        // const apiAccessToken = await getAccessTokenWithPopup({
        //   authorizationParams: {
        //     audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        //     scope: "read:current_user offline_access",
        //   },
        // });

        const apiAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user offline_access",
          },
        });

        console.log({ apiAccessToken });
        setApiToken(apiAccessToken || '');
        
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
            scope: "openid profile email offline_access read:current_user",
          },
        });
  
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user?.sub}`;
  
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const { user_metadata } = await metadataResponse.json();
  
        setUserMetadata(user_metadata);
      } catch (e: any) {
        console.log(e.message);
      }
    };
  
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (!apiToken) return;

    const fetchOrgs = async () => {
      fetch(`${process.env.REACT_APP_API}/organizations`, {
        headers: { Authorization: `Bearer ${apiToken}` }
      })
      .then(res => res.json())
      .then(data => {
        setOrganizations(data)
      });
    };

    const fetchSledGroups = async (organizationID: string) => {
      fetch(`${process.env.REACT_APP_API}/sled-groups?OrganizationID=${organizationID}`)
      .then(res => res.json())
      .then(data => setSledGroups(data));
    };

    const fetchSleds = async (organizationID: string, sledGroupID: string) => {
      fetch(`${process.env.REACT_APP_API}/sleds?OrganizationID=${organizationID}&SledGroupID=${sledGroupID}`)
      .then(res => res.json())
      .then(data => setSleds(data));
    };

    const fetchMissions = async (organizationID: string, sledGroupID: string) => {
      fetch(`${process.env.REACT_APP_API}/missions?OrganizationID=${organizationID}&SledGroupID=${sledGroupID}`)
      .then(res => res.json())
      .then(data => setMissions(data));
    };

    fetchOrgs();
    fetchSledGroups(organizationID ? organizationID : '');
    fetchSleds(organizationID ? organizationID : '', sledGroupID ? sledGroupID : '');
    fetchMissions(organizationID ? organizationID : '', sledGroupID ? sledGroupID : '');
  }, [apiToken]);
  
  useEffect(() => {
    if (organizations == null || sledGroups == null || sleds == null || missions == null) return;
    // Code here will run after *every* render
    const url = new URL(window.location.href);
    
    let oid = url.searchParams.get("organizationID") || organizations[0]?.organizationID;
    setOrganizationID(oid);
    const orgData = organizations.find((o: Organization) => o.organizationID === oid);
    setOrganization(orgData as Organization);

    let gid = url.searchParams.get("sledGroupID") || sledGroups[0]?.sledGroupID;
    setSledGroupID(gid);
    const groupData = sledGroups.find((g: SledGroup) => g.sledGroupID === gid);
    if (groupData) {
      setSledGroup(groupData);
      const updatedSledList = sleds?.filter((s: Sled) => s.sledGroupID === gid);
      setFilteredSleds(updatedSledList as Sled[]);
    }

    let sid = url.searchParams.get("sledID") || sleds[0]?.sledID;
    setSledID(sid);
    const sledData = sleds.find((s: Sled) => s.sledID === sid);
    if (sledData) {
      setSled(sledData);
      console.log({ message: 'setSledMission', sledData, missions })
      setSledMission(missions.find(m => m.missionName === sledData.lastMission));
      const controlOptions = getControlOptions(sledData.lastStatusMessage);
      setControls(controlOptions);
      window.history.replaceState(null, '', `/control?organizationID=${oid}&sledGroupID=${gid}&sledID=${sid}`);
    } else {
      window.history.replaceState(null, '', `/control?organizationID=${oid}&sledGroupID=${gid}`);
    }

    console.log({ gid, groupData, sid, sledData });

    window.history.replaceState(null, '', `/control?organizationID=${oid}&sledGroupID=${gid}&sledID=${sid}`);
  }, [window.location.href, organizations, sledGroups, sleds, missions]);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API}/hubs/sled`)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection && !connection.connectionId && sleds && filteredSleds) {
      connection
        .start()
        .then((result) => {
          console.log("Connected!");

          connection.on("SledStatusMessage", (sledID, status: SledStatus) => {
            console.log("SledStatusMessage", sledID, status, sleds, filteredSleds);

            if (sleds && filteredSleds) {
              const sledList = [...sleds.filter(s => s.sledID !== sledID)];
              const filteredSledList = [...filteredSleds.filter(s => s.sledID !== sledID)];

              const updated = filteredSleds.find(s => s.sledID === sledID);

              if (updated) {
                updated.lastStatusMessage = status.statusMessage;
                updated.lastStatusDate = status.statusDate;
                updated.lastMission = status.mission;
                updated.lastLatitude = status.latitude;
                updated.lastLongitude = status.longitude;
                updated.lastHeading = status.heading;
                updated.lastSpeedMS = status.speedMS;
                updated.lastBatteryPercentage = status.batteryPercentage;

                console.log('updated', updated);
                
                sledList.push(updated);
                filteredSledList.push(updated);

                if (updated.sledID === sled?.sledID) {
                  setSled(updated);
                }
              }

              setSleds(sledList);
              setFilteredSleds(filteredSledList);
            }
          });
        })
        .catch((e) => console.log("Connection failed: ", e));
    }
  }, [connection, sleds, filteredSleds, sled?.sledID]);

  const sendMessage = async (sid: string, message: string) => {
    const sledMessage = {
      sledID: sid,
      message,
    };

    if (connection) {
      try {
        await connection.send("NewMessage", sledMessage);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("No connection to server yet.");
    }
  };

  const changeMission = (mission: string) => {
    console.log("changeMission", sled?.mission, mission);
    const updatedSled = sled;
    // updatedSled.mission = mission;
    // setSled(updatedSled);
    // setSledMission(updatedSled.mission);
  };

  const changeSledGroup = (gid: string) => {
    if (!sledGroups) return;
    setSledGroupID(gid);
    const groupData = sledGroups.find((g: SledGroup) => g.sledGroupID === gid);
    setSledGroup(groupData as SledGroup);

    const updatedSledList = sleds?.filter((s: Sled) => s.sledGroupID === gid);
    setFilteredSleds(updatedSledList as Sled[]);

    const sledData = updatedSledList ? updatedSledList[0] : null;
    if (sledData) {
      setSled(sledData);
      setSledMission(missions?.find(m => m.missionName === sledData.lastMission));
      const controlOptions = getControlOptions(sledData.lastStatusMessage);
      setControls(controlOptions);
      window.history.pushState(null, '', `/control?organizationID=${organizationID}&sledGroupID=${gid}&sledID=${sledData.sledID}`);
    } else {
      window.history.pushState(null, '', `/control?organizationID=${organizationID}&sledGroupID=${gid}`);
    }
  };

  const changeSled = (sid: string) => {
    window.history.pushState(null, "", `/control?organizationID=${organizationID}&sledGroupID=${sledGroupID}&sledID=${sid}`);
    setSledID(sid);
    const sledData = sleds?.find((s: Sled) => s.sledID === sid);
    setSled(sledData as Sled);
    const missionData = missions?.find((m: Mission) => m.missionName === sledData?.lastMission);
    setSledMission(missionData);

    const controlOptions = getControlOptions(sledData?.lastStatusMessage);
    setControls(controlOptions);
  };

  const getControlOptions = (lastStatusMessage: string | undefined) => {
    let controlOptions = {
      select: false,
      load: true,
      clear: false,
      start: false,
      pause: false,
      continue: false,
      stop: false,
      shutdown: true,
    };

    if (lastStatusMessage) {
      switch (lastStatusMessage) {
        case "No Mission":
          console.log("case 1");
          break;
        case "Has Mission, Not Started" || "Mission Ended":
          console.log("case 2");
          controlOptions = {
            select: false,
            load: false,
            clear: true,
            start: true,
            pause: false,
            continue: false,
            stop: false,
            shutdown: true,
          };
          break;
        case "Running":
          console.log("case 3");
          controlOptions = {
            select: false,
            load: false,
            clear: false,
            start: false,
            pause: true,
            continue: false,
            stop: true,
            shutdown: false,
          };
          break;
        case "Waiting On Operator":
          console.log("case 4");
          controlOptions = {
            select: false,
            load: false,
            clear: false,
            start: false,
            pause: false,
            continue: true,
            stop: true,
            shutdown: false,
          };
          break;

        default:
          break;
      }
    }

    return controlOptions;
  }

  return (
    <>
    <Navbar organization={organization} />
    <div className="">
      <div className="row g-0">
        <div className="col">
          <div className="row mx-0 my-2">
            <div className="col-12">
              <SledGroupControl
                sledGroups={sledGroups as SledGroup[]}
                selectedSledGroup={sledGroup as SledGroup}
                onChange={changeSledGroup}
                onClick={sendMessage}
              />
              <hr />
              {filteredSleds &&
                filteredSleds.map((s: Sled) => (
                  <div
                    key={s.sledID}
                    className={s.sledID === sled?.sledID ? "card my-3 bg-success-subtle" : "card my-3"}
                    style={{ width: "18rem" }}
                    onClick={() => changeSled(s.sledID)}
                  >
                    <div className="card-body">
                      <h5 className="card-title">{s.sledName}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">
                        {s.lastMission}
                      </h6>
                      <p className="card-text">{s.lastStatusMessage}</p>
                      <a href="#" className="card-link">
                        Sled Location
                      </a>
                      <a href="#" className="card-link">
                        Mission Plan
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="col-8">
          <MissionControlMap 
          sledGroups={sledGroups as SledGroup[]} 
          sleds={sleds as Sled[]} 
          missions={missions as Mission[]}
          activeSledGroup={sledGroup as SledGroup} 
          activeSled={sled as Sled} 
          activeMission={sledMission as Mission}
          />
        </div>
        <div className="col">
          <div className="row mx-0 my-2">
            <div className="col-12">
              {sled && (
                <div className="">
                  <h3 className="text-center my-3">{sled.sledName}</h3>
                  <img
                    src={sledSvg}
                    className="w-50 mx-auto d-block"
                    alt="Sled Image"
                  />
                  <hr />
                  {/* <h4 className="text-center my-3">{sled.lastStatus}</h4> */}
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <h5 className="">{sled.lastStatusMessage}</h5>
                    </li>
                    <li className="list-group-item">
                      {new Date(sled?.lastStatusDate).toLocaleString()}
                    </li>
                    <li className="list-group-item">
                      Speed: {sled.lastSpeedMS} mph
                    </li>
                    <li className="list-group-item">
                      Heading: {sled.lastHeading}&deg;
                    </li>
                    <li className="list-group-item">
                      Latitude: {sled.lastLatitude}
                    </li>
                    <li className="list-group-item">
                      Longitude: {sled.lastLongitude}
                    </li>
                    <li className="list-group-item">
                      Battery: {sled.lastBatteryPercentage}%
                    </li>
                  </ul>
                  <hr />
                  <div className="mb-3">
                    <select
                      className="form-select"
                      aria-label="Sled Mission Select"
                      value={sledMission?.missionName}
                      onChange={(e) => changeMission(e.target.value)}
                      placeholder="Select a Mission"
                      disabled={!controls.select}
                    >
                      {/* <option>Select a Mission</option> */}
                      {missions &&
                        missions.map((m: Mission) => (
                          <option key={m.missionID} value={m.missionName}>
                            {m.missionName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-start mb-2">
                    <button
                      className="btn btn-success w-50"
                      onClick={() => console.log("load clicked")}
                      disabled={!controls.load}
                    >
                      Load
                    </button>
                    <button
                      className="btn btn-danger w-50 ms-2"
                      onClick={() => console.log("clear clicked")}
                      disabled={!controls.clear}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="d-flex justify-content-start mb-2">
                    <button
                      className="btn btn-success w-100"
                      onClick={() => console.log("start mission clicked")}
                      disabled={!controls.start}
                    >
                      Start Mission
                    </button>
                  </div>
                  <div className="d-flex justify-content-start mb-2">
                    <button
                      className="btn btn-warning w-50"
                      onClick={() => console.log("pause clicked")}
                      disabled={!controls.pause}
                    >
                      Pause
                    </button>
                    <button
                      className="btn btn-success w-50 ms-2"
                      onClick={() => console.log("continue clicked")}
                      disabled={!controls.continue}
                    >
                      Continue
                    </button>
                  </div>
                  <div className="d-flex justify-content-start mb-2">
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => console.log("stop mission clicked")}
                      disabled={!controls.stop}
                    >
                      Stop Mission
                    </button>
                  </div>
                  <div className="d-flex justify-content-start mb-2">
                    <button
                      className="btn btn-danger w-100"
                      onClick={() => console.log("shut down clicked")}
                      disabled={!controls.shutdown}
                    >
                      Shut Down
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default withAuthenticationRequired(MissionControl);
